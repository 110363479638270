import { createTheme } from '@mui/material/styles'

// Some high-level customizations
export const theme = createTheme({
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginTop: 4,
                    marginBottom: 10,
                    marginRight: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red',
                },
            },
        },
    },
})
