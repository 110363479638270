import { Box, FormControl, FormHelperText, TextField } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import { messages } from './PersonalInvolvement'
import { KnowledgeSource } from './KnowledgeSource'
import {
    FORMAL_AVALANCHE_SAFETY_COURSE,
    MENTORSHIP,
    VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA,
    PRACTICE_WITH_PEERS,
    PERSONAL_EXPERIENCE_IN_THE_FIELD,
    SELF_STUDY,
    ENGAGING_IN_ONLINE_COMMUNITIES,
    KNOWLEDGE_SOURCE_OTHER_TEXT,
    KNOWLEDGE_SOURCE_OTHER,
} from 'components/UserResearchPanel/validationSchema'
import { useValidateField } from 'stores/UserResearchPanelSignupStore'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

export const KnowledgeSources = () => {
    const { data, updateData, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()
    const { formatMessage } = useIntl()

    const handleKnowledgeSourceOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateData({ [KNOWLEDGE_SOURCE_OTHER_TEXT]: e.target.value })
        setTouched(KNOWLEDGE_SOURCE_OTHER_TEXT, true)
        validateField(KNOWLEDGE_SOURCE_OTHER_TEXT, e.target.value)
    }

    return (
        <FormControl sx={styles.formControl}>
            <QuestionText>
                <FormattedMessage {...messages.knowledge_source_importance} />
            </QuestionText>
            <FormHelperText>
                <FormattedMessage {...messages.knowledge_source_importance_helper_text} />
            </FormHelperText>
            <Box sx={styles.flex}>
                <KnowledgeSource knowledgeSourceField={PRACTICE_WITH_PEERS} />
                <KnowledgeSource knowledgeSourceField={PERSONAL_EXPERIENCE_IN_THE_FIELD} />
                <KnowledgeSource knowledgeSourceField={MENTORSHIP} />
                <KnowledgeSource knowledgeSourceField={FORMAL_AVALANCHE_SAFETY_COURSE} />
                <KnowledgeSource knowledgeSourceField={SELF_STUDY} />
                <KnowledgeSource knowledgeSourceField={VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA} />
                <KnowledgeSource knowledgeSourceField={ENGAGING_IN_ONLINE_COMMUNITIES} />
                <QuestionText>
                    <FormattedMessage {...messages.other} />
                </QuestionText>
                <TextField
                    sx={styles.activityInput}
                    value={data[KNOWLEDGE_SOURCE_OTHER_TEXT as keyof typeof data] || ''}
                    onChange={handleKnowledgeSourceOtherChange}
                    placeholder={formatMessage(messages.knowledge_source_other)}
                />
                <KnowledgeSource knowledgeSourceField={KNOWLEDGE_SOURCE_OTHER} />
            </Box>
        </FormControl>
    )
}

const styles = {
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    clearIcon: {
        marginRight: 2,
    },
    activityInput: {
        margin: '4px 0',
        width: '100%',
    },
    flex: {
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
    },
}
