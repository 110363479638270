import { FormattedMessage } from 'react-intl'
import { FormControlLabel, Radio, RadioGroup, FormControl, FormHelperText } from '@mui/material'

import { UserResearchPanelSignupStore, useValidateField } from 'stores/UserResearchPanelSignupStore'
import { importanceMessages } from './Importance'
import { IMPORTANCE_OPTIONS } from './Importance'
import { KNOWLEDGE_SOURCE_KEYS } from './Knowledge'
import { knowledgeMessages } from './Knowledge'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

export const KnowledgeSource = ({
    knowledgeSourceField,
}: {
    knowledgeSourceField: keyof typeof KNOWLEDGE_SOURCE_KEYS
}) => {
    const { data, updateData, touched, errors, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()

    return (
        <FormControl sx={styles.formControl} error={touched[knowledgeSourceField] && !!errors[knowledgeSourceField]}>
            <QuestionText>
                <FormattedMessage
                    {...knowledgeMessages[
                        KNOWLEDGE_SOURCE_KEYS[knowledgeSourceField].messageKey as keyof typeof knowledgeMessages
                    ]}
                />
            </QuestionText>
            <RadioGroup
                row
                value={data[knowledgeSourceField as keyof typeof data] || ''}
                onChange={e => {
                    updateData({ [knowledgeSourceField]: e.target.value })
                    setTouched(knowledgeSourceField, true)
                    validateField(knowledgeSourceField, e.target.value)
                }}
                sx={styles.radioGroup}
            >
                {IMPORTANCE_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={<FormattedMessage {...importanceMessages[option]} />}
                        sx={styles.radioLabel}
                    />
                ))}
            </RadioGroup>
            {touched[knowledgeSourceField as keyof typeof touched] &&
                errors[knowledgeSourceField as keyof typeof errors] && (
                    <FormHelperText>{errors[knowledgeSourceField as keyof typeof errors]}</FormHelperText>
                )}
        </FormControl>
    )
}

const styles = {
    formControl: {
        width: '100%',
        margin: '4px 0',
    },
    radioGroup: {
        justifyContent: 'space-between',
        gap: 1,
        width: '100%',
        flexDirection: 'column',
    },
    radioLabel: {
        margin: 0,
        textAlign: 'center',
        '& .MuiFormControlLabel-label': {
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'nowrap',
        },
    },
}
