import { defineMessages } from 'react-intl'

export const BACKCOUNTRY_SKIING_SNOWBOARDING = 'backcountry_skiing_snowboarding'
export const OUT_OF_BOUNDS_SKIING = 'out_of_bounds_skiing'
export const MOUNTAIN_SNOWMOBILING_SNOWBIKING = 'mountain_snowmobiling_snowbiking'
export const ALPINE_MOUNTAINEERING = 'alpine_mountaineering'
export const ICE_CLIMBING = 'ice_climbing'
export const SNOWSHOEING_OR_WINTER_HIKING = 'snowshoeing_or_winter_hiking'
export const OTHER = 'other'

export const ACTIVITIES = [
    { name: BACKCOUNTRY_SKIING_SNOWBOARDING },
    { name: OUT_OF_BOUNDS_SKIING },
    { name: MOUNTAIN_SNOWMOBILING_SNOWBIKING },
    { name: ALPINE_MOUNTAINEERING },
    { name: ICE_CLIMBING },
    { name: SNOWSHOEING_OR_WINTER_HIKING },
    { name: OTHER },
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const messages = defineMessages({
    backcountry_skiing_snowboarding: {
        id: 'UserResearchPanel.BackcountryActivities.backcountry_skiing_snowboarding',
        defaultMessage: 'Backcountry skiing/snowboarding',
    },
    out_of_bounds_skiing: {
        id: 'UserResearchPanel.BackcountryActivities.out_of_bounds_skiing',
        defaultMessage: 'Out-of-bounds Skiing',
    },
    mountain_snowmobiling_snowbiking: {
        id: 'UserResearchPanel.BackcountryActivities.mountain_snowmobiling_snowbiking',
        defaultMessage: 'Mountain snowmobiling/snowbiking',
    },
    alpine_mountaineering: {
        id: 'UserResearchPanel.BackcountryActivities.alpine_mountaineering',
        defaultMessage: 'Alpine mountaineering',
    },
    ice_climbing: {
        id: 'UserResearchPanel.BackcountryActivities.ice_climbing',
        defaultMessage: 'Ice climbing',
    },
    snowshoeing_or_winter_hiking: {
        id: 'UserResearchPanel.BackcountryActivities.snowshoeing_or_winter_hiking',
        defaultMessage: 'Snowshoeing or winter hiking',
    },
    other: {
        id: 'UserResearchPanel.BackcountryActivities.other',
        defaultMessage: 'Other',
    },
    'UserResearchPanel.BackcountryActivities.backcountryActivitiesFirst': {
        id: 'UserResearchPanel.BackcountryActivities.most_common_activity',
        defaultMessage: 'Most common activity',
    },
    'UserResearchPanel.BackcountryActivities.backcountryActivitiesSecond': {
        id: 'UserResearchPanel.BackcountryActivities.second_most_common_activity',
        defaultMessage: 'Second most common activity',
    },
    'UserResearchPanel.BackcountryActivities.backcountryActivitiesThird': {
        id: 'UserResearchPanel.BackcountryActivities.third_most_common_activity',
        defaultMessage: 'Third most common activity',
    },
})
