import { useIntl } from 'react-intl'

import { Consent } from './SignupSteps/Consent'
import { DataPrivacy } from './SignupSteps/DataPrivacy'
import { Background } from './SignupSteps/Background'
import { BackcountryActivities } from './SignupSteps/BackcountryActivities/BackcountryActivities'
import { Experience } from './SignupSteps/Experience/Experience'
import { Motivations } from './SignupSteps/Motivations/Motivations'
import {
    createConsentSchema,
    createDataPrivacySchema,
    createBackgroundSchema,
    createBackcountryActivitiesSchema,
    createExperienceSchema,
    createMotivationsSchema,
} from './validationSchema'
import { useActiveStep, useIsCurrentStepValid } from 'stores/UserResearchPanelSignupStore'

export const CONSENT = 0
export const DATA_PRIVACY = 1
export const BACKGROUND = 2
export const BACKCOUNTRY_ACTIVITIES = 3
export const EXPERIENCE = 4
export const MOTIVATIONS = 5

export const useUserResearchPanelSteps = () => {
    const intl = useIntl()

    return [
        {
            id: CONSENT,
            label: intl.formatMessage({
                id: 'urp.steps.consent.label',
                defaultMessage: 'Consent',
            }),
            component: <Consent />,
            validationSchema: createConsentSchema(intl),
        },
        {
            id: DATA_PRIVACY,
            label: intl.formatMessage({
                id: 'urp.steps.privacy.label',
                defaultMessage: 'Data Privacy',
            }),
            component: <DataPrivacy />,
            validationSchema: createDataPrivacySchema(intl),
        },
        {
            id: BACKGROUND,
            label: intl.formatMessage({
                id: 'urp.steps.background.label',
                defaultMessage: 'Background',
            }),
            component: <Background />,
            validationSchema: createBackgroundSchema(intl),
        },
        {
            id: BACKCOUNTRY_ACTIVITIES,
            label: intl.formatMessage({
                id: 'urp.steps.backcountry.label',
                defaultMessage: 'Backcountry Activities',
            }),
            component: <BackcountryActivities />,
            validationSchema: createBackcountryActivitiesSchema(intl),
        },
        {
            id: EXPERIENCE,
            label: intl.formatMessage({
                id: 'urp.steps.experience.label',
                defaultMessage: 'Experience',
            }),
            component: <Experience />,
            validationSchema: createExperienceSchema(intl),
        },
        {
            id: MOTIVATIONS,
            label: intl.formatMessage({
                id: 'urp.steps.motivations.label',
                defaultMessage: 'Motivations & Decision Making',
            }),
            component: <Motivations />,
            validationSchema: createMotivationsSchema(),
        },
    ]
}

export const useUserResearchPanelStep = (stepId: number) => {
    const steps = useUserResearchPanelSteps()
    return steps.find(step => step.id === stepId)
}

export const useIsLastStep = () => {
    const activeStep = useActiveStep()
    const steps = useUserResearchPanelSteps()
    const isCurrentStepValid = useIsCurrentStepValid()

    return activeStep === steps.length - 1 && isCurrentStepValid
}
