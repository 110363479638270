import { useAuth0 } from '@auth0/auth0-react'
import { APIClient } from 'services/api/APIClient'
import { UserResearchPanelFormData } from 'stores/UserResearchPanelSignupStore'
import useSWR from 'swr'

// We cannot access the bearer token without using a hook, so it must be passed in here for POST/PUT/DELETE requests
export const postUserResearchPanelData = async (data: UserResearchPanelFormData, bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }

    return APIClient.post('/userResearchPanel', data, authHeader)
}

export const getUserResearchPanelData = (bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }

    return APIClient.get('/userResearchPanel', authHeader)
}

export const useUserResearchPanelData = () => {
    const { getAccessTokenSilently, user } = useAuth0()

    return useSWR(
        ['userResearchPanelData', user?.sub],
        async () => {
            const token = await getAccessTokenSilently()
            return getUserResearchPanelData(token)
        },
        {
            revalidateOnFocus: false,
        }
    )
}

export const deleteUserResearchPanelData = async (bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }
    return APIClient.delete('/userResearchPanel', authHeader)
}

type PartialUserResearchPanelFormData = {
    [K in keyof UserResearchPanelFormData]?: UserResearchPanelFormData[K]
}

export const updateUserResearchPanelData = async (data: PartialUserResearchPanelFormData, bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }
    return APIClient.put('/userResearchPanel', data, authHeader)
}
