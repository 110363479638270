import { FormattedMessage } from 'react-intl'
import { FormControl, FormHelperText, Box, FormControlLabel, Radio } from '@mui/material'

import {
    DAYS_WORKED_PER_SEASON_OPTIONS,
    PROFESSIONAL_ROLE_QUESTION_KEYS,
    ProfessionalRoleKey,
    checkIfDaysWorkedPerSeasonIsMoreThan10,
} from './PersonalInvolvement'
import { messages as personalInvolvementMessages } from './PersonalInvolvement'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { YearSelect } from 'components/UserResearchPanel/CommonInputs/YearSelect'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

type Props = {
    professionalRoleKey: ProfessionalRoleKey
}

export const ProfessionalQuestions = ({ professionalRoleKey }: Props) => {
    const { data, updateData, setTouched } = UserResearchPanelSignupStore()
    const {
        daysWorkedPerSeasonKey,
        yearBecameProfessionalKey,
        daysWorkedPerSeasonMessageKey,
        yearBecameProfessionalMessageKey,
    } = PROFESSIONAL_ROLE_QUESTION_KEYS[professionalRoleKey]

    const handleDaysWorkedPerSeasonChange = (daysWorkedPerSeason: string) => {
        updateData({ [daysWorkedPerSeasonKey]: daysWorkedPerSeason })
        setTouched(daysWorkedPerSeasonKey, true)
    }

    const daysWorkedPerSeasonIsMoreThan10 =
        typeof data[daysWorkedPerSeasonKey as keyof typeof data] === 'string' &&
        checkIfDaysWorkedPerSeasonIsMoreThan10(data[daysWorkedPerSeasonKey as keyof typeof data] as string)

    return (
        <>
            <FormControl sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        {...personalInvolvementMessages[
                            daysWorkedPerSeasonMessageKey as keyof typeof personalInvolvementMessages
                        ]}
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {DAYS_WORKED_PER_SEASON_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option.name}
                            control={
                                <Radio
                                    checked={data[daysWorkedPerSeasonKey as keyof typeof data] === option.name}
                                    onChange={() => handleDaysWorkedPerSeasonChange(option.name)}
                                />
                            }
                            label={<FormattedMessage {...personalInvolvementMessages[option.name]} />}
                        />
                    ))}
                </Box>
            </FormControl>
            {daysWorkedPerSeasonIsMoreThan10 && (
                <FormControl sx={styles.formControl}>
                    <QuestionText>
                        <FormattedMessage
                            {...personalInvolvementMessages[
                                yearBecameProfessionalMessageKey as keyof typeof personalInvolvementMessages
                            ]}
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please enter the year even if it is just approximate."
                        />
                    </FormHelperText>
                    <YearSelect dataKey={yearBecameProfessionalKey as keyof typeof data} />
                </FormControl>
            )}
        </>
    )
}

const styles = {
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
    },
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    yearInput: {
        width: 300,
        marginTop: 1,
    },
}
