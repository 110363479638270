import ClearIcon from '@mui/icons-material/Clear'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material'
import { CldImage } from 'next-cloudinary'
import { FormattedMessage } from 'react-intl'

import {
    ACTIVITIES,
    ALPINE_MOUNTAINEERING,
    BACKCOUNTRY_SKIING_SNOWBOARDING,
    ICE_CLIMBING,
    messages,
    MOUNTAIN_SNOWMOBILING_SNOWBIKING,
    OUT_OF_BOUNDS_SKIING,
    SNOWSHOEING_OR_WINTER_HIKING,
} from 'components/UserResearchPanel/SignupSteps/BackcountryActivities/Activities'
import {
    FREQUENCY_OPTIONS,
    frequencyMessages,
} from 'components/UserResearchPanel/SignupSteps/BackcountryActivities/Frequencies'
import {
    BACKCOUNTRY_ACTIVITIES_FIRST,
    BACKCOUNTRY_ACTIVITIES_SECOND,
    BACKCOUNTRY_ACTIVITIES_THIRD,
    BACKCOUNTRY_EXPERIENCE_DAYS,
    BACKCOUNTRY_EXPERIENCE_WINTERS,
    ICE_CLIMBING_EXPOSURE_FREQUENCY,
    REGION,
    SNOWSHOEING_TRAIL_FREQUENCY,
    TERRAIN_FREQUENCIES_BOS_CHALLENGING,
    TERRAIN_FREQUENCIES_BOS_COMPLEX,
    TERRAIN_FREQUENCIES_BOS_EXTREME,
    TERRAIN_FREQUENCIES_BOS_SIMPLE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
    TERRAIN_FREQUENCIES_SHM_CHALLENGING,
    TERRAIN_FREQUENCIES_SHM_COMPLEX,
    TERRAIN_FREQUENCIES_SHM_EXTREME,
    TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_SHM_SIMPLE,
    TIME_PERIOD,
    WEEKDAYS_IN_BACKCOUNTRY,
} from 'components/UserResearchPanel/validationSchema'
import { UserResearchPanelSignupStore, useValidateField } from 'stores/UserResearchPanelSignupStore'
import { DAYS_PER_WINTER, daysPerWinterMessages } from './DaysPerWinter'
import { EXPERIENCE_YEARS, experienceYearsMessages } from './NumberOfWinters'
import { REGION_OPTIONS, regionMessages } from './Regions'
import { TIME_PERIODS, timePeriodMessages } from './TimePeriods'
import { WEEKDAYS_IN_BACKCOUNTRY_OPTIONS, weekDaysInBackcountryMessages } from './WeekDaysInBackcountry'
import { useATESDescriptionsSHM, useATESDescriptionsIceClimbing, useATESDescriptionsBOS } from './useATESDescriptions'
import { ATESRow } from './ATESRow'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

const LEVELS = [BACKCOUNTRY_ACTIVITIES_FIRST, BACKCOUNTRY_ACTIVITIES_SECOND, BACKCOUNTRY_ACTIVITIES_THIRD] as const
const SHM_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_SHM_SIMPLE,
    TERRAIN_FREQUENCIES_SHM_CHALLENGING,
    TERRAIN_FREQUENCIES_SHM_COMPLEX,
    TERRAIN_FREQUENCIES_SHM_EXTREME,
] as const
const SHM_ACTIVITIES = [SNOWSHOEING_OR_WINTER_HIKING, ALPINE_MOUNTAINEERING]
const ICE_CLIMBING_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
] as const
const BOS_ACTIVITIES = [BACKCOUNTRY_SKIING_SNOWBOARDING, OUT_OF_BOUNDS_SKIING, MOUNTAIN_SNOWMOBILING_SNOWBIKING]
const BOS_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_BOS_SIMPLE,
    TERRAIN_FREQUENCIES_BOS_CHALLENGING,
    TERRAIN_FREQUENCIES_BOS_COMPLEX,
    TERRAIN_FREQUENCIES_BOS_EXTREME,
] as const

export const BackcountryActivities = () => {
    const { data, updateData, touched, errors, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()
    const selectedRegions = (data[REGION] as string[]) || []
    const selectedTimePeriods = (data[TIME_PERIOD] as string[]) || []
    const selectedWeekDaysInBackcountry = (data[WEEKDAYS_IN_BACKCOUNTRY] as string[]) || []
    const ATESDescriptionsSHM = useATESDescriptionsSHM()
    const ATESDescriptionsIceClimbing = useATESDescriptionsIceClimbing()
    const ATESDescriptionsBOS = useATESDescriptionsBOS()

    const handleRegionChange = (option: string) => {
        const updatedSelection = selectedRegions.includes(option)
            ? selectedRegions.filter(region => region !== option)
            : [...selectedRegions, option]

        updateData({ [REGION]: updatedSelection })
        setTouched(REGION, true)
        validateField(REGION, updatedSelection)
    }

    const handleTimePeriodChange = (option: string) => {
        const updatedSelection = selectedTimePeriods.includes(option)
            ? selectedTimePeriods.filter(timePeriod => timePeriod !== option)
            : [...selectedTimePeriods, option]

        updateData({ [TIME_PERIOD]: updatedSelection })
        setTouched(TIME_PERIOD, true)
        validateField(TIME_PERIOD, updatedSelection)
    }

    const handleWeekDaysInBackcountryChange = (option: string) => {
        const updatedSelection = selectedWeekDaysInBackcountry.includes(option)
            ? selectedWeekDaysInBackcountry.filter(weekDay => weekDay !== option)
            : [...selectedWeekDaysInBackcountry, option]

        updateData({ [WEEKDAYS_IN_BACKCOUNTRY]: updatedSelection })
        setTouched(WEEKDAYS_IN_BACKCOUNTRY, true)
        validateField(WEEKDAYS_IN_BACKCOUNTRY, updatedSelection)
    }

    return (
        <Box>
            <FormControl sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="What recreational winter backcountry activities do you most often engage in?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Please select at least one activity in the first dropdown."
                    />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {LEVELS.map(level => {
                        return (
                            <TextField
                                key={level}
                                select
                                sx={styles.activityInput}
                                value={data[level] || ''}
                                onChange={e => {
                                    updateData({ [level]: e.target.value })
                                    setTouched(level, true)
                                    validateField(level, e.target.value)
                                }}
                                label={
                                    <FormattedMessage
                                        {...messages[`UserResearchPanel.BackcountryActivities.${level}`]}
                                    />
                                }
                                InputProps={{
                                    endAdornment: typeof data[level] === 'string' && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    updateData({ [level]: null })
                                                    setTouched(level, true)
                                                    validateField(level, '')
                                                }}
                                                edge="end"
                                                size="small"
                                                sx={styles.clearIcon}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={touched[level] && !!errors[level]}
                                helperText={touched[level] && errors[level]}
                            >
                                {ACTIVITIES.map(activity => {
                                    const otherLevels = LEVELS.filter(l => l !== level)
                                    const disabled = otherLevels.some(l => data[l] === activity.name)

                                    return (
                                        <MenuItem key={activity.name} value={activity.name} disabled={disabled}>
                                            <FormattedMessage {...messages[activity.name]} />
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        )
                    })}
                </Box>
            </FormControl>
            {data[BACKCOUNTRY_ACTIVITIES_FIRST] === SNOWSHOEING_OR_WINTER_HIKING && (
                <FormControl sx={styles.formControl} required>
                    <QuestionText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When snowshoeing/winter hiking, how often do you leave marked trails and create your own path?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectOneOption} />
                    </FormHelperText>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={data[SNOWSHOEING_TRAIL_FREQUENCY] || ''}
                        onChange={e => {
                            updateData({ [SNOWSHOEING_TRAIL_FREQUENCY]: e.target.value })
                            setTouched(SNOWSHOEING_TRAIL_FREQUENCY, true)
                            validateField(SNOWSHOEING_TRAIL_FREQUENCY, e.target.value)
                        }}
                        error={touched[SNOWSHOEING_TRAIL_FREQUENCY] && !!errors[SNOWSHOEING_TRAIL_FREQUENCY]}
                        helperText={touched[SNOWSHOEING_TRAIL_FREQUENCY] && errors[SNOWSHOEING_TRAIL_FREQUENCY]}
                    >
                        {FREQUENCY_OPTIONS.map(option => (
                            <MenuItem key={option} value={option}>
                                <FormattedMessage {...frequencyMessages[option]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
            {data[BACKCOUNTRY_ACTIVITIES_FIRST] === ICE_CLIMBING && (
                <FormControl sx={styles.formControl} required>
                    <QuestionText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="How often would you estimate that your ice climbs are exposed to avalanche hazard from above?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectOneOption} />
                    </FormHelperText>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={data[ICE_CLIMBING_EXPOSURE_FREQUENCY] || ''}
                        onChange={e => {
                            updateData({ [ICE_CLIMBING_EXPOSURE_FREQUENCY]: e.target.value })
                            setTouched(ICE_CLIMBING_EXPOSURE_FREQUENCY, true)
                            validateField(ICE_CLIMBING_EXPOSURE_FREQUENCY, e.target.value)
                        }}
                        error={touched[ICE_CLIMBING_EXPOSURE_FREQUENCY] && !!errors[ICE_CLIMBING_EXPOSURE_FREQUENCY]}
                        helperText={touched[SNOWSHOEING_TRAIL_FREQUENCY] && errors[SNOWSHOEING_TRAIL_FREQUENCY]}
                    >
                        {FREQUENCY_OPTIONS.map(option => (
                            <MenuItem key={option} value={option}>
                                <FormattedMessage {...frequencyMessages[option]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
            <FormControl component="fieldset" sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Overall, how much experience do you have in all your recreational winter backcountry activities combined?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Please select the appropriate option in each dropdown."
                    />
                </FormHelperText>
                <Box sx={styles.flex}>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={data[BACKCOUNTRY_EXPERIENCE_WINTERS] || ''}
                        onChange={e => {
                            updateData({ [BACKCOUNTRY_EXPERIENCE_WINTERS]: e.target.value })
                            setTouched(BACKCOUNTRY_EXPERIENCE_WINTERS, true)
                            validateField(BACKCOUNTRY_EXPERIENCE_WINTERS, e.target.value)
                        }}
                        label={<FormattedMessage defaultMessage="Number of winters" />}
                        error={touched[BACKCOUNTRY_EXPERIENCE_WINTERS] && !!errors[BACKCOUNTRY_EXPERIENCE_WINTERS]}
                        helperText={touched[BACKCOUNTRY_EXPERIENCE_WINTERS] && errors[BACKCOUNTRY_EXPERIENCE_WINTERS]}
                    >
                        {EXPERIENCE_YEARS.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                                <FormattedMessage {...experienceYearsMessages[option.name]} />
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={data[BACKCOUNTRY_EXPERIENCE_DAYS] || ''}
                        onChange={e => {
                            updateData({ [BACKCOUNTRY_EXPERIENCE_DAYS]: e.target.value })
                            setTouched(BACKCOUNTRY_EXPERIENCE_DAYS, true)
                            validateField(BACKCOUNTRY_EXPERIENCE_DAYS, e.target.value)
                        }}
                        label={<FormattedMessage defaultMessage="Average number of days per winter" />}
                        error={touched[BACKCOUNTRY_EXPERIENCE_DAYS] && !!errors[BACKCOUNTRY_EXPERIENCE_DAYS]}
                        helperText={touched[BACKCOUNTRY_EXPERIENCE_DAYS] && errors[BACKCOUNTRY_EXPERIENCE_DAYS]}
                    >
                        {DAYS_PER_WINTER.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                                <FormattedMessage {...daysPerWinterMessages[option.name]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </FormControl>
            <FormControl sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Which of the following regions do you commonly visit for winter backcountry recreation?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.imageContainer}>
                    <CldImage
                        config={{ cloud: { cloudName: 'avalanche-ca' } }}
                        src={
                            'https://res.cloudinary.com/avalanche-ca/image/upload/v1736449479/User%20Research%20Panel/General_regions.png'
                        }
                        alt={'Regions'}
                        width={940}
                        height={761}
                        sizes="100vw"
                        style={styles.image}
                        crop="fill"
                    />
                </Box>
                {REGION_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedRegions.includes(option)}
                                onChange={() => handleRegionChange(option)}
                            />
                        }
                        label={<FormattedMessage {...regionMessages[option]} />}
                    />
                ))}
            </FormControl>
            <FormControl sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="During what part of the winter season do you typically recreate in these regions?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                {TIME_PERIODS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedTimePeriods.includes(option)}
                                onChange={() => handleTimePeriodChange(option)}
                            />
                        }
                        label={<FormattedMessage {...timePeriodMessages[option]} />}
                    />
                ))}
            </FormControl>
            <FormControl sx={styles.formControl} required>
                <QuestionText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="On what days do you typically recreate in these regions?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                {WEEKDAYS_IN_BACKCOUNTRY_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedWeekDaysInBackcountry.includes(option)}
                                onChange={() => handleWeekDaysInBackcountryChange(option)}
                            />
                        }
                        label={<FormattedMessage {...weekDaysInBackcountryMessages[option]} />}
                    />
                ))}
            </FormControl>
            {SHM_ACTIVITIES.includes(data[BACKCOUNTRY_ACTIVITIES_FIRST] as string) && (
                <FormControl component="fieldset" sx={styles.formControl} required>
                    <QuestionText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {SHM_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsSHM[terrainType].title}
                            description={ATESDescriptionsSHM[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsSHM[terrainType].cloudinaryUrl}
                        >
                            <FormControl
                                error={touched[terrainType] && !!errors[terrainType]}
                                sx={styles.activityInput}
                            >
                                <RadioGroup
                                    value={data[terrainType] || ''}
                                    onChange={e => {
                                        updateData({ [terrainType]: e.target.value })
                                        setTouched(terrainType, true)
                                        validateField(terrainType, e.target.value)
                                    }}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
            {data[BACKCOUNTRY_ACTIVITIES_FIRST] === ICE_CLIMBING && (
                <FormControl component="fieldset" sx={styles.formControl} required>
                    <QuestionText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {ICE_CLIMBING_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsIceClimbing[terrainType].title}
                            description={ATESDescriptionsIceClimbing[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsIceClimbing[terrainType].cloudinaryUrl}
                        >
                            <FormControl
                                error={touched[terrainType] && !!errors[terrainType]}
                                sx={styles.activityInput}
                            >
                                <RadioGroup
                                    value={data[terrainType] || ''}
                                    onChange={e => {
                                        updateData({ [terrainType]: e.target.value })
                                        setTouched(terrainType, true)
                                        validateField(terrainType, e.target.value)
                                    }}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
            {BOS_ACTIVITIES.includes(data[BACKCOUNTRY_ACTIVITIES_FIRST] as string) && (
                <FormControl component="fieldset" sx={styles.formControl} required>
                    <QuestionText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {BOS_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsBOS[terrainType].title}
                            description={ATESDescriptionsBOS[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsBOS[terrainType].cloudinaryUrl}
                        >
                            <FormControl
                                error={touched[terrainType] && !!errors[terrainType]}
                                sx={styles.activityInput}
                            >
                                <RadioGroup
                                    value={data[terrainType] || ''}
                                    onChange={e => {
                                        updateData({ [terrainType]: e.target.value })
                                        setTouched(terrainType, true)
                                        validateField(terrainType, e.target.value)
                                    }}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
        </Box>
    )
}

const styles = {
    activityInput: {
        margin: '4px 0',
        width: '100%',
    },
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    clearIcon: {
        marginRight: 2,
    },
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexWrap: 'wrap' as const,
    },
    imageContainer: {
        width: '100%',
        margin: '4px auto',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    terrainRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        marginTop: 2,
    },
    terrainLabel: {
        minWidth: 300,
    },
    terrainInput: {
        width: '100%',
    },
}
