import { ReactNode } from 'react'

import { Typography, Grid, Box } from '@mui/material'
import { CldImage } from 'next-cloudinary'

export const ATESRow = ({
    title,
    description,
    cloudinaryUrl,
    children,
}: {
    title: string | ReactNode
    description: string
    cloudinaryUrl: string
    children?: ReactNode
}) => {
    return (
        <Box sx={styles.box}>
            <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" sx={styles.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                    <Box>
                        <Typography>{description}</Typography>
                    </Box>
                    <Box sx={styles.children}>{children}</Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                    <CldImage
                        config={{ cloud: { cloudName: 'avalanche-ca' } }}
                        src={cloudinaryUrl}
                        alt={title as string}
                        width={600}
                        height={400}
                        sizes="(max-width: 768px) 100vw, 50vw"
                        style={styles.image}
                        crop="fill"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const styles = {
    box: {
        marginTop: '24px',
        borderTop: '1px solid var(--gray-light)',
        paddingTop: '4px',
    },
    children: {
        marginTop: 1,
        marginBottom: 1,
    },
    title: {
        marginTop: 2,
        marginBottom: 1,
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '4px',
    },
}
