import { FormattedMessage, useIntl } from 'react-intl'

import { Box, RadioGroup } from '@mui/material'
import { FormControl, FormHelperText, FormControlLabel, Radio, Checkbox } from '@mui/material'
import {
    DECISION_MAKING_CONTRIBUTION,
    INFORMATION_SOURCES_CONSULTED,
    AVALANCHE_FORECAST_USAGE,
    AVALANCHE_FORECAST_CHECKED_FREQUENCY,
    SAFETY_EQUIPMENT_TYPICALLY_USED,
    TRACK_WITH_GPS,
    GPS_TRACKING_PLATFORMS,
    GPS_TRACKING_PLATFORM_OTHER,
} from 'components/UserResearchPanel/validationSchema'
import { UserResearchPanelSignupStore, useValidateField } from 'stores/UserResearchPanelSignupStore'
import {
    DECISION_MAKING_CONTRIBUTION_OPTIONS,
    INFORMATION_SOURCES_CONSULTED_OPTIONS,
    motivationMessages,
    INFORMATION_SOURCES_CONSULTED_OTHER,
    REGIONAL_AVALANCHE_FORECAST,
    AVALANCHE_FORECAST_USAGE_OPTIONS,
    AVALANCHE_FORECAST_CHECKED_FREQUENCY_OPTIONS,
    SAFETY_EQUIPMENT_TYPICALLY_USED_OPTIONS,
    GPS_PLATFORM_OPTIONS,
} from './MotivationConstants'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

export const Motivations = () => {
    const { formatMessage } = useIntl()
    const { data, updateData, updateBooleanValue, touched, errors, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()

    const handleInformationSourcesConsultedChange = (option: string) => {
        const newValue = Array.isArray(data[INFORMATION_SOURCES_CONSULTED])
            ? data[INFORMATION_SOURCES_CONSULTED].includes(option)
                ? data[INFORMATION_SOURCES_CONSULTED].filter(item => item !== option)
                : [...(data[INFORMATION_SOURCES_CONSULTED] as string[]), option]
            : [option]

        updateData({ [INFORMATION_SOURCES_CONSULTED]: newValue })
        setTouched(INFORMATION_SOURCES_CONSULTED, true)
        validateField(INFORMATION_SOURCES_CONSULTED, newValue)
    }

    const handleSafetyEquipmentTypicallyUsedChange = (option: string) => {
        const newValue = Array.isArray(data[SAFETY_EQUIPMENT_TYPICALLY_USED])
            ? data[SAFETY_EQUIPMENT_TYPICALLY_USED].includes(option)
                ? data[SAFETY_EQUIPMENT_TYPICALLY_USED].filter(item => item !== option)
                : [...(data[SAFETY_EQUIPMENT_TYPICALLY_USED] as string[]), option]
            : [option]

        updateData({ [SAFETY_EQUIPMENT_TYPICALLY_USED]: newValue })
        setTouched(SAFETY_EQUIPMENT_TYPICALLY_USED, true)
        validateField(SAFETY_EQUIPMENT_TYPICALLY_USED, newValue)
    }

    const handleGPSPlatformChange = (option: string) => {
        const newValue = Array.isArray(data[GPS_TRACKING_PLATFORMS])
            ? data[GPS_TRACKING_PLATFORMS].includes(option)
                ? data[GPS_TRACKING_PLATFORMS].filter(item => item !== option)
                : [...(data[GPS_TRACKING_PLATFORMS] as string[]), option]
            : [option]

        updateData({ [GPS_TRACKING_PLATFORMS]: newValue })
    }

    const showOtherInformationSources =
        Array.isArray(data[INFORMATION_SOURCES_CONSULTED]) &&
        data[INFORMATION_SOURCES_CONSULTED].includes(INFORMATION_SOURCES_CONSULTED_OTHER)
    const avalancheForecastSelected =
        Array.isArray(data[INFORMATION_SOURCES_CONSULTED]) &&
        data[INFORMATION_SOURCES_CONSULTED].includes(REGIONAL_AVALANCHE_FORECAST)
    const trackWithGPS = data[TRACK_WITH_GPS]
    const showOtherGPSPlatform =
        (trackWithGPS as boolean) &&
        Array.isArray(data[GPS_TRACKING_PLATFORMS]) &&
        data[GPS_TRACKING_PLATFORMS].includes(GPS_TRACKING_PLATFORM_OTHER)

    return (
        <>
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage
                        defaultMessage="Which of the following statements best describes how you typically contribute to the decision on when and where to go recreating in the backcountry and any avalanche risk management decision in the field?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                <RadioGroup
                    value={data[DECISION_MAKING_CONTRIBUTION] || ''}
                    onChange={e => {
                        updateData({ [DECISION_MAKING_CONTRIBUTION]: e.target.value })
                        setTouched(DECISION_MAKING_CONTRIBUTION, true)
                    }}
                >
                    {DECISION_MAKING_CONTRIBUTION_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            sx={styles.radioGroupTopAligned}
                            control={<Radio />}
                            label={
                                <FormattedMessage {...motivationMessages[option as keyof typeof motivationMessages]} />
                            }
                        />
                    ))}
                </RadioGroup>
                {touched[DECISION_MAKING_CONTRIBUTION] && errors[DECISION_MAKING_CONTRIBUTION] && (
                    <FormHelperText error>{errors[DECISION_MAKING_CONTRIBUTION]}</FormHelperText>
                )}
            </FormControl>
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage
                        defaultMessage="When planning a backcountry trip, which of the following information sources do you typically consult for getting an understanding of the current avalanche conditions?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {INFORMATION_SOURCES_CONSULTED_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={
                                        Array.isArray(data[INFORMATION_SOURCES_CONSULTED])
                                            ? data[INFORMATION_SOURCES_CONSULTED].includes(option)
                                            : false
                                    }
                                    onChange={() => handleInformationSourcesConsultedChange(option)}
                                />
                            }
                            label={
                                <FormattedMessage {...motivationMessages[option as keyof typeof motivationMessages]} />
                            }
                        />
                    ))}
                </Box>
                {touched[INFORMATION_SOURCES_CONSULTED] && errors[INFORMATION_SOURCES_CONSULTED] && (
                    <FormHelperText error>{errors[INFORMATION_SOURCES_CONSULTED]}</FormHelperText>
                )}
                {showOtherInformationSources && (
                    <TextInput
                        dataKey={INFORMATION_SOURCES_CONSULTED_OTHER}
                        placeholder={formatMessage(motivationMessages.informationSourcesConsultedOther)}
                        sx={{ mt: 2 }}
                    />
                )}
            </FormControl>
            {avalancheForecastSelected && (
                <>
                    <FormControl sx={styles.formControl}>
                        <QuestionText>
                            <FormattedMessage
                                defaultMessage="How often do you use the avalanche forecast to check avalanche conditions?"
                                description="User Research Panel"
                            />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...commonMessages.selectOneOption} />
                        </FormHelperText>
                        <RadioGroup
                            value={data[AVALANCHE_FORECAST_CHECKED_FREQUENCY] || ''}
                            onChange={e => {
                                updateData({ [AVALANCHE_FORECAST_CHECKED_FREQUENCY]: e.target.value })
                                setTouched(AVALANCHE_FORECAST_CHECKED_FREQUENCY, true)
                                validateField(AVALANCHE_FORECAST_CHECKED_FREQUENCY, e.target.value)
                            }}
                        >
                            {AVALANCHE_FORECAST_CHECKED_FREQUENCY_OPTIONS.map(option => (
                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={
                                        <FormattedMessage
                                            {...motivationMessages[option as keyof typeof motivationMessages]}
                                        />
                                    }
                                />
                            ))}
                        </RadioGroup>
                        {touched[AVALANCHE_FORECAST_CHECKED_FREQUENCY] &&
                            errors[AVALANCHE_FORECAST_CHECKED_FREQUENCY] && (
                                <FormHelperText error>{errors[AVALANCHE_FORECAST_CHECKED_FREQUENCY]}</FormHelperText>
                            )}
                    </FormControl>
                    <FormControl sx={styles.formControl}>
                        <QuestionText>
                            <FormattedMessage
                                defaultMessage="Which of the following statements best describes your use of the avalanche forecast when planning a backcountry trip?"
                                description="User Research Panel"
                            />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...commonMessages.selectOneOption} />
                        </FormHelperText>
                        <RadioGroup
                            value={data[AVALANCHE_FORECAST_USAGE] || ''}
                            onChange={e => {
                                updateData({ [AVALANCHE_FORECAST_USAGE]: e.target.value })
                                setTouched(AVALANCHE_FORECAST_USAGE, true)
                                validateField(AVALANCHE_FORECAST_USAGE, e.target.value)
                            }}
                        >
                            {AVALANCHE_FORECAST_USAGE_OPTIONS.map(option => (
                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    sx={styles.radioGroupTopAligned}
                                    control={<Radio />}
                                    label={
                                        <FormattedMessage
                                            {...motivationMessages[option as keyof typeof motivationMessages]}
                                        />
                                    }
                                />
                            ))}
                        </RadioGroup>
                        {touched[AVALANCHE_FORECAST_USAGE] && errors[AVALANCHE_FORECAST_USAGE] && (
                            <FormHelperText error>{errors[AVALANCHE_FORECAST_USAGE]}</FormHelperText>
                        )}
                    </FormControl>
                </>
            )}
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage
                        defaultMessage="Which of the following safety equipment items do you typically bring into the backcountry in the winter?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {SAFETY_EQUIPMENT_TYPICALLY_USED_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            control={
                                <Checkbox
                                    checked={
                                        Array.isArray(data[SAFETY_EQUIPMENT_TYPICALLY_USED])
                                            ? data[SAFETY_EQUIPMENT_TYPICALLY_USED].includes(option)
                                            : false
                                    }
                                    onChange={() => handleSafetyEquipmentTypicallyUsedChange(option)}
                                />
                            }
                            label={formatMessage(motivationMessages[option as keyof typeof motivationMessages])}
                        />
                    ))}
                </Box>
            </FormControl>
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage
                        defaultMessage="Do you use a GPS tracking device to track your location in the backcountry?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <RadioGroup
                    value={data[TRACK_WITH_GPS]}
                    onChange={e => {
                        updateBooleanValue(TRACK_WITH_GPS, e.target.value)
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label={formatMessage(motivationMessages.yes)} />
                    <FormControlLabel value={false} control={<Radio />} label={formatMessage(motivationMessages.no)} />
                </RadioGroup>
            </FormControl>
            {trackWithGPS && (
                <FormControl sx={styles.formControl}>
                    <QuestionText>
                        <FormattedMessage
                            defaultMessage="Select all platforms you use to share GPS tracks and trip reports"
                            description="User Research Panel"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                    </FormHelperText>
                    <Box sx={styles.flex}>
                        {GPS_PLATFORM_OPTIONS.map(option => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={
                                    <Checkbox
                                        checked={
                                            Array.isArray(data[GPS_TRACKING_PLATFORMS]) &&
                                            data[GPS_TRACKING_PLATFORMS].includes(option)
                                        }
                                        onChange={() => handleGPSPlatformChange(option)}
                                    />
                                }
                                label={
                                    <FormattedMessage
                                        {...motivationMessages[option as keyof typeof motivationMessages]}
                                    />
                                }
                            />
                        ))}
                    </Box>
                    {showOtherGPSPlatform && (
                        <TextInput
                            dataKey={GPS_TRACKING_PLATFORM_OTHER}
                            placeholder={formatMessage(motivationMessages.gpsTrackingPlatformOther)}
                            sx={{ mt: 2 }}
                        />
                    )}
                </FormControl>
            )}
        </>
    )
}

const styles = {
    formControl: {
        mt: 4,
        width: '100%',
    },
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
    },
    radioGroupTopAligned: {
        alignItems: 'flex-start',
        '& .MuiRadio-root': {
            paddingTop: '3px',
        },
    },
}
