import * as Yup from 'yup'
import { IntlShape } from 'react-intl'

import { messages } from './ValidationSchemaMessages'

export const CURRENT_YEAR = new Date().getFullYear()
export const YEAR_OPTIONS = Array.from({ length: 101 }, (_, i) => CURRENT_YEAR - i)
export const MIN_YEAR = 1900
export const MAX_YEAR = new Date().getFullYear()
// Step 1: CONSENT
export const INFORMED_CONSENT = 'informedConsent'
export const createConsentSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [INFORMED_CONSENT]: Yup.boolean()
            .required(intl.formatMessage(messages.informedConsentRequired))
            .oneOf([true], intl.formatMessage(messages.informedConsentRequired)),
    })

// Step 2: DATA_PRIVACY
export const LINKING_DATA_CONSENT = 'linkingDataConsent'
export const createDataPrivacySchema = (intl: IntlShape) =>
    Yup.object().shape({
        [LINKING_DATA_CONSENT]: Yup.boolean()
            .required(intl.formatMessage(messages.dataPrivacyRequired))
            .oneOf([true, false], intl.formatMessage(messages.dataPrivacyRequired)),
    })

// Step 3: BACKGROUND
export const GENDER = 'gender'
export const YEAR_OF_BIRTH = 'yearOfBirth'
export const COUNTRY = 'country'
export const PROVINCE_OR_STATE = 'provinceOrState'
export const CITY = 'city'
export const POSTAL_CODE = 'postalCode'
export const EMAIL = 'email'
export const HOW_DID_YOU_HEAR = 'howDidYouHear'
export const HOW_DID_YOU_HEAR_OTHER = 'howDidYouHearOther'

export const createBackgroundSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [GENDER]: Yup.string().required(intl.formatMessage(messages.genderRequired)),
        [YEAR_OF_BIRTH]: Yup.number()
            .required(intl.formatMessage(messages.yearOfBirthRequired))
            .min(MIN_YEAR, intl.formatMessage(messages.yearOfBirthMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearOfBirthMax)),
        [COUNTRY]: Yup.string().required(intl.formatMessage(messages.countryRequired)),
        [PROVINCE_OR_STATE]: Yup.string().nullable(),
        [CITY]: Yup.string().required(intl.formatMessage(messages.cityRequired)),
        [POSTAL_CODE]: Yup.string()
            .required(intl.formatMessage(messages.postalCodeRequired))
            .min(3, intl.formatMessage(messages.postalCodeMin)),
        [EMAIL]: Yup.string().email(intl.formatMessage(messages.emailInvalid)),
        [HOW_DID_YOU_HEAR]: Yup.array().of(Yup.string()).nullable(),
        [HOW_DID_YOU_HEAR_OTHER]: Yup.string().nullable(),
    })

// Step 4: BACKCOUNTRY_ACTIVITIES
export const BACKCOUNTRY_ACTIVITIES_FIRST = 'backcountryActivitiesFirst'
export const BACKCOUNTRY_ACTIVITIES_SECOND = 'backcountryActivitiesSecond'
export const BACKCOUNTRY_ACTIVITIES_THIRD = 'backcountryActivitiesThird'
export const SNOWSHOEING_TRAIL_FREQUENCY = 'snowshoeingTrailFrequency'
export const ICE_CLIMBING_EXPOSURE_FREQUENCY = 'iceClimbingExposureFrequency'
export const BACKCOUNTRY_EXPERIENCE_WINTERS = 'backcountryExperienceWinters'
export const BACKCOUNTRY_EXPERIENCE_DAYS = 'backcountryExperienceDays'
export const REGION = 'region'
export const TIME_PERIOD = 'timePeriod'
export const WEEKDAYS_IN_BACKCOUNTRY = 'weekDaysInBackcountry'
// ======== Terrain Frequency questions ========
// Snowshoeing, Hike, Mountaineering
export const TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE = 'terrainFrequenciesSHMNonAvalanche'
export const TERRAIN_FREQUENCIES_SHM_SIMPLE = 'terrainFrequenciesSHMSimple'
export const TERRAIN_FREQUENCIES_SHM_CHALLENGING = 'terrainFrequenciesSHMChallenging'
export const TERRAIN_FREQUENCIES_SHM_COMPLEX = 'terrainFrequenciesSHMComplex'
export const TERRAIN_FREQUENCIES_SHM_EXTREME = 'terrainFrequenciesSHMExtreme'
// Ice Climbing
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE = 'terrainFrequenciesIceClimbingNonAvalanche'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE = 'terrainFrequenciesIceClimbingSimple'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING = 'terrainFrequenciesIceClimbingChallenging'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX = 'terrainFrequenciesIceClimbingComplex'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME = 'terrainFrequenciesIceClimbingExtreme'
// Backcountry Skiing, out of bounds skiing, snowmobiling
export const TERRAIN_FREQUENCIES_BOS_SIMPLE = 'terrainFrequenciesBOSSimple'
export const TERRAIN_FREQUENCIES_BOS_CHALLENGING = 'terrainFrequenciesBOSChallenging'
export const TERRAIN_FREQUENCIES_BOS_COMPLEX = 'terrainFrequenciesBOSComplex'
export const TERRAIN_FREQUENCIES_BOS_EXTREME = 'terrainFrequenciesBOSExtreme'
// ======== Terrain Frequency questions ========

export const createBackcountryActivitiesSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [BACKCOUNTRY_ACTIVITIES_FIRST]: Yup.string().required(
            intl.formatMessage(messages.mostCommonBackcountryActivityRequired)
        ),
        [BACKCOUNTRY_ACTIVITIES_SECOND]: Yup.string().nullable(),
        [BACKCOUNTRY_ACTIVITIES_THIRD]: Yup.string().nullable(),
        [SNOWSHOEING_TRAIL_FREQUENCY]: Yup.string(),
        [ICE_CLIMBING_EXPOSURE_FREQUENCY]: Yup.string(),
        [BACKCOUNTRY_EXPERIENCE_WINTERS]: Yup.string().required(intl.formatMessage(messages.numberWintersRequired)),
        [BACKCOUNTRY_EXPERIENCE_DAYS]: Yup.string().required(intl.formatMessage(messages.daysPerWinterRequired)),
        [REGION]: Yup.array().of(Yup.string()).required(intl.formatMessage(messages.regionRequired)),
        [TIME_PERIOD]: Yup.array().of(Yup.string()).required(intl.formatMessage(messages.timePeriodRequired)),
        [WEEKDAYS_IN_BACKCOUNTRY]: Yup.array()
            .of(Yup.string())
            .required(intl.formatMessage(messages.weekDaysInBackcountryRequired)),
        [TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE]: Yup.string(),
        [TERRAIN_FREQUENCIES_SHM_SIMPLE]: Yup.string(),
        [TERRAIN_FREQUENCIES_SHM_CHALLENGING]: Yup.string(),
        [TERRAIN_FREQUENCIES_SHM_COMPLEX]: Yup.string(),
        [TERRAIN_FREQUENCIES_SHM_EXTREME]: Yup.string(),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE]: Yup.string(),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE]: Yup.string(),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING]: Yup.string(),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX]: Yup.string(),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME]: Yup.string(),
        [TERRAIN_FREQUENCIES_BOS_SIMPLE]: Yup.string(),
        [TERRAIN_FREQUENCIES_BOS_CHALLENGING]: Yup.string(),
        [TERRAIN_FREQUENCIES_BOS_COMPLEX]: Yup.string(),
        [TERRAIN_FREQUENCIES_BOS_EXTREME]: Yup.string(),
    })

// Step 5: EXPERIENCE
export const PERSONAL_INVOLVEMENT = 'personalInvolvement'
export const PERSONAL_INVOLVEMENT_OTHER = 'personalInvolvementOther'
export const EDUCATOR_CERTIFICATION = 'educatorCertification'
export const EDUCATOR_CERTIFICATION_OTHER = 'educatorCertificationOther'
export const EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON = 'educatorPaidCoursesMultipleTimesPerSeason'
export const CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON = 'certifiedMountainGuideDaysWorkedPerSeason'
export const CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON = 'certifiedSkiGuideDaysWorkedPerSeason'
export const ALPINE_SAR_DAYS_WORKED_PER_SEASON = 'alpineSAROrgDaysWorkedPerSeason'
export const SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON =
    'skiPatrolManagingRiskSkiAreaDaysWorkedPerSeason'
export const AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON = 'avalancheProfessionalDaysWorkedPerSeason'
export const AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON = 'avalancheSafetyEducatorDaysWorkedPerSeason'
export const PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON = 'publicAvalancheForecasterDaysWorkedPerSeason'
export const AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON = 'avalancheResearcherDaysWorkedPerSeason'
export const CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL = 'certifiedMountainGuideYearBecameProfessional'
export const CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL = 'certifiedSkiGuideYearBecameProfessional'
export const ALPINE_SAR_YEAR_BECAME_PROFESSIONAL = 'alpineSAROrgYearBecameProfessional'
export const SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL = 'skiPatrolManagingRiskSkiAreaYearProfessional'
export const AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL = 'avalancheProfessionalYearBecameProfessional'
export const AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL = 'avalancheSafetyEducatorYearBecameProfessional'
export const PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL = 'publicAvalancheForecasterYearBecameProfessional'
export const AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL = 'avalancheResearcherYearBecameProfessional'
export const PRACTICE_WITH_PEERS = 'practiceWithPeersAtASimilarExperienceLevel'
export const PERSONAL_EXPERIENCE_IN_THE_FIELD = 'personalExperienceInTheField'
export const MENTORSHIP = 'mentorshipFromAMoreExperiencedPerson'
export const FORMAL_AVALANCHE_SAFETY_COURSE = 'formalAvalancheSafetyCourseFromAvEdProvider'
export const SELF_STUDY = 'selfStudyOfBooksAndOnlineTutorials'
export const VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA = 'videosOrContentOnSocialMedia'
export const ENGAGING_IN_ONLINE_COMMUNITIES = 'engagingInOnlineCommunities'
export const KNOWLEDGE_SOURCE_OTHER = 'knowledgeSourceOther'
export const KNOWLEDGE_SOURCE_OTHER_TEXT = 'knowledgeSourceOtherText'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED = 'highestLevelTrainingCourseCompleted'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER = 'highestLevelTrainingCourseCompletedOther'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR = 'highestLevelTrainingCourseCompletedYear'
export const TRAINING_COURSE_COUNT = 'trainingCourseCount'

export const createExperienceSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [PERSONAL_INVOLVEMENT]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.personalInvolvementRequired))
            .required(intl.formatMessage(messages.personalInvolvementRequired)),
        [PERSONAL_INVOLVEMENT_OTHER]: Yup.string().nullable(),
        [EDUCATOR_CERTIFICATION]: Yup.array().of(Yup.string()),
        [EDUCATOR_CERTIFICATION_OTHER]: Yup.string().nullable(),
        [EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON]: Yup.boolean(),
        [CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [ALPINE_SAR_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON]: Yup.string(),
        [CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [ALPINE_SAR_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(new Date().getFullYear(), intl.formatMessage(messages.yearBecameProfessionalMax)),
        [PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [PRACTICE_WITH_PEERS]: Yup.string().nullable(),
        [PERSONAL_EXPERIENCE_IN_THE_FIELD]: Yup.string().nullable(),
        [MENTORSHIP]: Yup.string().nullable(),
        [FORMAL_AVALANCHE_SAFETY_COURSE]: Yup.string().nullable(),
        [SELF_STUDY]: Yup.string().nullable(),
        [VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA]: Yup.string().nullable(),
        [ENGAGING_IN_ONLINE_COMMUNITIES]: Yup.string().nullable(),
        [KNOWLEDGE_SOURCE_OTHER]: Yup.string().nullable(),
        [KNOWLEDGE_SOURCE_OTHER_TEXT]: Yup.string().nullable(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED]: Yup.string().nullable(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER]: Yup.string().nullable(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR]: Yup.number()
            .nullable()
            .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
            .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
        [TRAINING_COURSE_COUNT]: Yup.string().nullable(),
    })

// Step 6: MOTIVATIONS
export const DECISION_MAKING_CONTRIBUTION = 'decisionMakingContribution'
export const INFORMATION_SOURCES_CONSULTED = 'informationSourcesConsulted'
export const INFORMATION_SOURCES_CONSULTED_OTHER = 'informationSourcesConsultedOther'
export const AVALANCHE_FORECAST_CHECKED_FREQUENCY = 'avalancheForecastCheckedFrequency'
export const AVALANCHE_FORECAST_USAGE = 'avalancheForecastUsage'
export const SAFETY_EQUIPMENT_TYPICALLY_USED = 'safetyEquipmentTypicallyUsed'
export const TRACK_WITH_GPS = 'trackWithGPS'
export const GPS_TRACKING_PLATFORMS = 'gpsTrackingPlatforms'
export const GPS_TRACKING_PLATFORM_OTHER = 'gpsTrackingPlatformOther'

export const createMotivationsSchema = () =>
    Yup.object().shape({
        [DECISION_MAKING_CONTRIBUTION]: Yup.string().nullable(),
        [INFORMATION_SOURCES_CONSULTED]: Yup.array().of(Yup.string()).nullable(),
        [INFORMATION_SOURCES_CONSULTED_OTHER]: Yup.string().nullable(),
        [AVALANCHE_FORECAST_CHECKED_FREQUENCY]: Yup.string().nullable(),
        [AVALANCHE_FORECAST_USAGE]: Yup.string().nullable(),
        [SAFETY_EQUIPMENT_TYPICALLY_USED]: Yup.array().of(Yup.string()).nullable(),
        [TRACK_WITH_GPS]: Yup.string().nullable(),
        [GPS_TRACKING_PLATFORMS]: Yup.array().of(Yup.string()).nullable(),
        [GPS_TRACKING_PLATFORM_OTHER]: Yup.string().nullable(),
    })

// Combined schema for the entire form
export const createUserResearchPanelSchema = (intl: IntlShape) => {
    const consentSchema = createConsentSchema(intl)
    const dataPrivacySchema = createDataPrivacySchema(intl)
    const backgroundSchema = createBackgroundSchema(intl)
    const backcountryActivitiesSchema = createBackcountryActivitiesSchema(intl)
    const experienceSchema = createExperienceSchema(intl)
    const motivationsSchema = createMotivationsSchema()

    return Yup.object().shape({
        ...consentSchema.fields,
        ...dataPrivacySchema.fields,
        ...backgroundSchema.fields,
        ...backcountryActivitiesSchema.fields,
        ...experienceSchema.fields,
        ...motivationsSchema.fields,
    })
}

// Types for each step and the complete form
export type ConsentFormValues = ReturnType<typeof createConsentSchema>['__outputType']
export type PersonalInfoFormValues = ReturnType<typeof createDataPrivacySchema>['__outputType']
export type BackgroundFormValues = ReturnType<typeof createBackgroundSchema>['__outputType']
export type BackcountryActivitiesFormValues = ReturnType<typeof createBackcountryActivitiesSchema>['__outputType']
export type ExperienceFormValues = ReturnType<typeof createExperienceSchema>['__outputType']
export type MotivationsFormValues = ReturnType<typeof createMotivationsSchema>['__outputType']
export type UserResearchPanelFormValues = ReturnType<typeof createUserResearchPanelSchema>['__outputType']
