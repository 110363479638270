import { SxProps, TextField, Theme } from '@mui/material'

import { useValidateField } from 'stores/UserResearchPanelSignupStore'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { UserResearchPanelFormValues } from '../validationSchema'

type TextInputProps = {
    dataKey: keyof UserResearchPanelFormValues
    placeholder: string
    fullWidth?: boolean
    sx?: SxProps<Theme>
    required?: boolean
}

export const TextInput = ({ dataKey, placeholder, fullWidth = true, sx, required = false }: TextInputProps) => {
    const { data, updateData, touched, errors, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()

    return (
        <TextField
            fullWidth={fullWidth}
            required={required}
            value={data[dataKey as keyof typeof data] || ''}
            onChange={e => {
                updateData({ [dataKey]: e.target.value })
                validateField(dataKey, e.target.value)
            }}
            onBlur={() => {
                setTouched(dataKey, true)
                validateField(dataKey, data[dataKey as keyof typeof data] as string)
            }}
            error={touched[dataKey as keyof typeof touched] && !!errors[dataKey as keyof typeof errors]}
            helperText={touched[dataKey as keyof typeof touched] && errors[dataKey as keyof typeof errors]}
            placeholder={placeholder}
            label={placeholder}
            sx={sx}
        />
    )
}
