import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { LINKING_DATA_CONSENT } from '../validationSchema'

export const DataPrivacy = () => {
    const { data, updateBooleanValue } = UserResearchPanelSignupStore()

    return (
        <div>
            <h2>
                <FormattedMessage description="User Research Panel" defaultMessage="Use of Data" />
            </h2>
            <Typography variant="body1">
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="We want you to decide how we use your data. We will run a variety of studies and gather data on how you
                navigate and interact with our website. We are not interested in what you do outside our site, and we
                will keep all of your information anonymous and private. Combining website analytics with other study
                data will help us better understand our audience, how you use our products, and how we can improve them
                to meet your needs. You can change your data-linking preferences at any time or request more details on
                how your data is used."
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Do you consent to linking your website behaviors with other study data?"
                    />
                </p>
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    name="consent"
                    value={data[LINKING_DATA_CONSENT]}
                    onChange={e => updateBooleanValue(LINKING_DATA_CONSENT, e.target.value)}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Yes, I consent to having my data linked"
                            />
                        }
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="No, I do not consent to having my data linked"
                            />
                        }
                    />
                </RadioGroup>
            </FormControl>
            <h2>
                <FormattedMessage description="User Research Panel" defaultMessage="Data Use and Retention" />
            </h2>
            <Typography variant="body1">
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="All information you provide here will be confidentially stored on secure servers. Any contact
                information you provide will only be used to contact you to participate in specific studies. The data
                you produce may be presented in academic publications or technical presentations and will be used to
                inform the design of future avalanche safety products. Your identity will always remain anonymous. You
                may opt-out of participation at any time and you may also request to have all of your data destroyed
                permanently. Your data will be stored on secure servers."
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="While the data collected throughout this research will be stored indefinitely to facilitate
                    communications and long term studies, you have full control over your data and can change how you want
                    them to be used at any time. To opt-out and delete your data or if you have any questions, please
                    contact us at <a>research@avalanche.ca</a>. We will remind you this
                    is an option in every email contacting you to participate in a new study."
                        values={{
                            a: chunks => <a href="mailto:research@avalanche.ca">{chunks}</a>,
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Note that your information will never be shared outside of Avalanche Canada and the Avalanche Research
                    Program at Simon Fraser University."
                    />
                </p>
            </Typography>
        </div>
    )
}
