import { defineMessages } from 'react-intl'

export const ONE_TO_TWO_DAYS_PER_WINTER = 'one_to_two_days_per_winter'
export const THREE_TO_FIVE_DAYS_PER_WINTER = 'three_to_five_days_per_winter'
export const SIX_TO_TEN_DAYS_PER_WINTER = 'six_to_ten_days_per_winter'
export const ELEVEN_TO_TWENTY_DAYS_PER_WINTER = 'eleven_to_twenty_days_per_winter'
export const TWENTY_ONE_TO_FIFTY_DAYS_PER_WINTER = 'twenty_one_to_fifty_days_per_winter'
export const MORE_THAN_FIFTY_DAYS_PER_WINTER = 'more_than_fifty_days_per_winter'

export const DAYS_PER_WINTER = [
    { name: ONE_TO_TWO_DAYS_PER_WINTER },
    { name: THREE_TO_FIVE_DAYS_PER_WINTER },
    { name: SIX_TO_TEN_DAYS_PER_WINTER },
    { name: ELEVEN_TO_TWENTY_DAYS_PER_WINTER },
    { name: TWENTY_ONE_TO_FIFTY_DAYS_PER_WINTER },
    { name: MORE_THAN_FIFTY_DAYS_PER_WINTER },
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const daysPerWinterMessages = defineMessages({
    one_to_two_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.one_to_two_days_per_winter',
        defaultMessage: '1-2 days per winter',
    },
    three_to_five_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.three_to_five_days_per_winter',
        defaultMessage: '3-5 days per winter',
    },
    six_to_ten_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.six_to_ten_days_per_winter',
        defaultMessage: '6-10 days per winter',
    },
    eleven_to_twenty_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.eleven_to_twenty_days_per_winter',
        defaultMessage: '11-20 days per winter',
    },
    twenty_one_to_fifty_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.twenty_one_to_fifty_days_per_winter',
        defaultMessage: '21-50 days per winter',
    },
    more_than_fifty_days_per_winter: {
        id: 'UserResearchPanel.BackcountryActivities.more_than_fifty_days_per_winter',
        defaultMessage: 'More than 50 days per winter',
    },
})
