import { MenuItem } from '@mui/material'
import { Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { UserResearchPanelSignupStore, useValidateField } from 'stores/UserResearchPanelSignupStore'
import { YEAR_OPTIONS } from '../validationSchema'
import { UserResearchPanelFormValues } from '../validationSchema'

type YearSelectProps = {
    dataKey: keyof UserResearchPanelFormValues
}

export const YearSelect = ({ dataKey }: YearSelectProps) => {
    const { data, updateData, setTouched } = UserResearchPanelSignupStore()
    const validateField = useValidateField()

    return (
        <Select
            sx={styles.yearInput}
            value={data[dataKey] || undefined}
            onChange={e => {
                const value = e.target.value as string
                updateData({
                    [dataKey]: value,
                })
                setTouched(dataKey, true)
                validateField(dataKey, value)
            }}
            displayEmpty
        >
            <MenuItem value={undefined}>
                <FormattedMessage
                    description="User Research Panel year dropdown placeholder"
                    defaultMessage="Select year"
                />
            </MenuItem>
            {YEAR_OPTIONS.map(year => (
                <MenuItem key={year} value={year}>
                    {year}
                </MenuItem>
            ))}
        </Select>
    )
}

const styles = {
    yearInput: {
        width: 300,
        marginTop: 1,
    },
}
