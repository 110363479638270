import { useEffect, useRef } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import Crystal from '@avcan/crystal/components/Crystal'
import { identity } from '@avcan/utils/function'
import mixpanel from 'mixpanel-browser'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'contexts/mui-theme'

import { FORECAST_CLOSED, PAGE_UNLOADED } from '@avcan/constants/products/mixpanel'
import { Boundary as ErrorBoundary } from 'components/error'
import Head from 'components/Head'
import * as Authentication from 'contexts/authentication'
import * as Internationalization from 'contexts/internationalization'
import * as UserSettings from 'contexts/usersettings'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { Fallback } from 'layouts/pages'
import * as Analytics from 'services/analytics'
import * as Polyfills from 'services/polyfills'
import {
    useForecastHistory,
    useForecastId,
    useMapHistory,
    useSetForecastHistory,
    useSetForecastId,
} from 'stores/MixpanelHistoryStore'
import { useSetIsLoaded } from 'stores/MixpanelStore'
import { DangerRatingTable } from 'layouts/map/DangerRatingTable'
import { useDangerRatingModalStore } from 'stores/DangerRatingModalStore'

import '@avcan/crystal/styles'
import '../styles/index.css'

export default function Application({ Component, pageProps }) {
    const { getLayout = identity } = Component
    const children = getLayout(<Component {...pageProps} />)

    Analytics.useService()
    Polyfills.usePolyfills()

    return (
        <Crystal>
            <ThemeProvider theme={theme}>
                <Internationalization.Provider>
                    <Head />
                    <Authentication.Provider>
                        <ErrorBoundary fallback={<Fallback />}>
                            <UserSettings.Provider>
                                <AppWrapper>{children}</AppWrapper>
                            </UserSettings.Provider>
                        </ErrorBoundary>
                    </Authentication.Provider>
                    <NoScript />
                    <Analytics.Script />
                </Internationalization.Provider>
            </ThemeProvider>
        </Crystal>
    )
}

const AppWrapper = ({ children }) => {
    const { user } = useAuth0()
    const setIsLoaded = useSetIsLoaded()
    const mapHistory = useMapHistory()
    const forecastHistory = useForecastHistory()
    const setForecastHistory = useSetForecastHistory()
    const forecastId = useForecastId()
    const setForecastId = useSetForecastId()
    const sendTrackEvent = useSendTrackEvent()
    const trackingTimeoutRef = useRef(null)
    const { isOpen, close } = useDangerRatingModalStore()

    useEffect(() => {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '', {
            // Enable these in DEV mode to see console entries for Mixpanel requests
            // debug: true,
            // ignore_dnt: true,
            track_pageview: true,
        })

        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN && mixpanel.register({ platform: 'website' })
        setIsLoaded(true)
    }, [setIsLoaded])

    useEffect(() => {
        const handleBeforeUnload = () => {
            trackPageUnloaded()
        }

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                trackPageUnloaded()
            }
        }

        const trackPageUnloaded = () => {
            if (trackingTimeoutRef.current) {
                clearTimeout(trackingTimeoutRef.current)
            }

            // Set a very short timeout to prevent the event from being fired twice
            // Don't set it so long so that it doesn't fire before the page is unloaded
            trackingTimeoutRef.current = setTimeout(() => {
                sendTrackEvent(PAGE_UNLOADED, {
                    url: window.location.href,
                    mapHistory: mapHistory,
                })

                if (forecastHistory.length > 0) {
                    sendTrackEvent(FORECAST_CLOSED, {
                        forecastHistory,
                        forecastId,
                    })

                    setForecastHistory([])
                    setForecastId(null)
                }

                trackingTimeoutRef.current = null
            }, 50)
        }

        // Two event listeners, hopefully one will fire when they close the app.
        // The debounce (setTimout) will cancel one of the calls if they're both called as the app is closing.
        window.addEventListener('beforeunload', handleBeforeUnload)
        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            if (trackingTimeoutRef.current) {
                clearTimeout(trackingTimeoutRef.current)
            }
        }
    }, [forecastHistory, forecastId, mapHistory, setForecastHistory, setForecastId, user?.sub])

    return (
        <>
            {/* Render the danger rating modal high in the component tree since it is used on both the main map and the forecast pages */}
            <DangerRatingTable show={isOpen} close={close} />
            {children}
        </>
    )
}

// Util components
function NoScript() {
    return (
        <noscript>
            <h1>Avalanche Canada</h1>
            <p>
                For full functionality of this site, it is necessary to enable JavaScript. Here are the
                <a href="https://www.enable-javascript.com/">
                    instructions how to enable JavaScript in your web browser
                </a>
                .
            </p>
        </noscript>
    )
}
