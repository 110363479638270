import { defineMessages } from 'react-intl'

import {
    CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON,
    CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL,
    CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL,
    CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON,
    ALPINE_SAR_DAYS_WORKED_PER_SEASON,
    ALPINE_SAR_YEAR_BECAME_PROFESSIONAL,
    SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON,
    SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON,
    AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON,
    PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL,
    PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON,
    AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON,
    AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL,
} from 'components/UserResearchPanel/validationSchema'

export const PERSONAL_RECREATION = 'personal_recreation'
export const LEADER_FOR_ORGANIZED_RECREATIONAL_GROUP = 'leader_for_organized_recreational_group'
export const CERTIFIED_MOUNTAIN_GUIDE = 'certified_mountain_guide'
export const CERTIFIED_SKI_GUIDE = 'certified_ski_guide'
export const MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION = 'member_of_an_alpine_search_and_rescue_organization'
export const MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA =
    'member_of_ski_patrol_managing_avalanche_risk_at_ski_area'
export const AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES =
    'avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites'
export const AVALANCHE_SAFETY_EDUCATOR = 'avalanche_safety_educator'
export const PUBLIC_AVALANCHE_FORECASTER = 'public_avalanche_forecaster'
export const AVALANCHE_RESEARCHER = 'avalanche_researcher'
export const OTHER = 'other'

export const PERSONAL_INVOLVEMENT_OPTIONS = [
    { name: PERSONAL_RECREATION },
    { name: LEADER_FOR_ORGANIZED_RECREATIONAL_GROUP },
    { name: CERTIFIED_MOUNTAIN_GUIDE },
    { name: CERTIFIED_SKI_GUIDE },
    { name: MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION },
    { name: MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA },
    { name: AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES },
    { name: AVALANCHE_SAFETY_EDUCATOR },
    { name: PUBLIC_AVALANCHE_FORECASTER },
    { name: AVALANCHE_RESEARCHER },
    { name: OTHER },
] as const

export const PROFESSIONAL_ROLE_QUESTION_KEYS = {
    [CERTIFIED_MOUNTAIN_GUIDE]: {
        daysWorkedPerSeasonKey: CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'certified_mountain_guide_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'certified_mountain_guide_year_became_professional',
    },
    [CERTIFIED_SKI_GUIDE]: {
        daysWorkedPerSeasonKey: CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'certified_ski_guide_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'certified_ski_guide_year_became_professional',
    },
    [MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION]: {
        daysWorkedPerSeasonKey: ALPINE_SAR_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: ALPINE_SAR_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'member_of_an_alpine_search_and_rescue_organization_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'member_of_an_alpine_search_and_rescue_organization_year_became_professional',
    },
    [MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA]: {
        daysWorkedPerSeasonKey: SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey:
            'member_of_ski_patrol_managing_avalanche_risk_at_ski_area_days_worked_per_season',
        yearBecameProfessionalMessageKey:
            'member_of_ski_patrol_managing_avalanche_risk_at_ski_area_year_became_professional',
    },
    [AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES]: {
        daysWorkedPerSeasonKey: AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey:
            'avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_days_worked_per_season',
        yearBecameProfessionalMessageKey:
            'avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_year_became_professional',
    },
    [AVALANCHE_SAFETY_EDUCATOR]: {
        daysWorkedPerSeasonKey: AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'avalanche_safety_educator_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'avalanche_safety_educator_year_became_professional',
    },
    [PUBLIC_AVALANCHE_FORECASTER]: {
        daysWorkedPerSeasonKey: PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'public_avalanche_forecaster_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'public_avalanche_forecaster_year_became_professional',
    },
    [AVALANCHE_RESEARCHER]: {
        daysWorkedPerSeasonKey: AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON,
        yearBecameProfessionalKey: AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL,
        daysWorkedPerSeasonMessageKey: 'avalanche_researcher_days_worked_per_season',
        yearBecameProfessionalMessageKey: 'avalanche_researcher_year_became_professional',
    },
}

export type ProfessionalRoleKey = keyof typeof PROFESSIONAL_ROLE_QUESTION_KEYS

export const NEVER = 'never'
export const LESS_THAN_FIVE = 'less_than_five'
export const FIVE_TO_TEN = 'five_to_ten'
export const ELEVEN_TO_TWENTY = 'eleven_to_twenty'
export const TWENTY_ONE_TO_FIFTY = 'twenty_one_to_fifty'
export const MORE_THAN_FIFTY = 'more_than_fifty'

export const DAYS_WORKED_PER_SEASON_OPTIONS = [
    { name: NEVER },
    { name: LESS_THAN_FIVE },
    { name: FIVE_TO_TEN },
    { name: ELEVEN_TO_TWENTY },
    { name: TWENTY_ONE_TO_FIFTY },
    { name: MORE_THAN_FIFTY },
] as const

export const checkIfDaysWorkedPerSeasonIsMoreThan10 = (daysWorkedPerSeason: string) => {
    return (
        daysWorkedPerSeason === ELEVEN_TO_TWENTY ||
        daysWorkedPerSeason === TWENTY_ONE_TO_FIFTY ||
        daysWorkedPerSeason === MORE_THAN_FIFTY
    )
}

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const messages = defineMessages({
    personal_recreation: {
        id: 'UserResearchPanel.PersonalInvolvement.personal_recreation',
        defaultMessage: 'Personal recreation (i.e., unguided)',
        description: 'User Research Panel',
    },
    leader_for_organized_recreational_group: {
        id: 'UserResearchPanel.PersonalInvolvement.leader_for_organized_recreational_group',
        defaultMessage: 'Leader for organized recreational group or club (e.g. Alpine Club of Canada)',
        description: 'User Research Panel',
    },
    certified_mountain_guide: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_mountain_guide',
        defaultMessage: 'Certified mountain guide (e.g. ACMG, IFMGA)',
        description: 'User Research Panel',
    },
    certified_ski_guide: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_ski_guide',
        defaultMessage: 'Certified ski guide (e.g. CSGA, ACMG)',
        description: 'User Research Panel',
    },
    member_of_an_alpine_search_and_rescue_organization: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_an_alpine_search_and_rescue_organization',
        defaultMessage: 'Member of an alpine search and rescue organization',
        description: 'User Research Panel',
    },
    member_of_ski_patrol_managing_avalanche_risk_at_ski_area: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_ski_patrol_managing_avalanche_risk_at_ski_area',
        defaultMessage: 'Member of ski patrol managing avalanche risk at ski area',
        description: 'User Research Panel',
    },
    avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites',
        defaultMessage: 'Avalanche safety technician/engineer managing avalanche risk for infrastructure or worksites',
        description: 'User Research Panel',
    },
    avalanche_safety_educator: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_educator',
        defaultMessage: 'Avalanche safety educator',
        description: 'User Research Panel',
    },
    public_avalanche_forecaster: {
        id: 'UserResearchPanel.PersonalInvolvement.public_avalanche_forecaster',
        defaultMessage: 'Public avalanche forecaster',
        description: 'User Research Panel',
    },
    avalanche_researcher: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_researcher',
        defaultMessage: 'Avalanche researcher',
        description: 'User Research Panel',
    },
    other: {
        id: 'UserResearchPanel.PersonalInvolvement.other',
        defaultMessage: 'Other',
        description: 'User Research Panel',
    },
    otherPlaceholder: {
        id: 'UserResearchPanel.PersonalInvolvement.otherPlaceholder',
        defaultMessage: 'Please specify other activities',
        description: 'User Research Panel',
    },
    never: {
        id: 'UserResearchPanel.PersonalInvolvement.never',
        defaultMessage: 'Never',
        description: 'User Research Panel',
    },
    less_than_five: {
        id: 'UserResearchPanel.PersonalInvolvement.less_than_five',
        defaultMessage: 'Less than 5 days per winter',
        description: 'User Research Panel',
    },
    five_to_ten: {
        id: 'UserResearchPanel.PersonalInvolvement.five_to_ten',
        defaultMessage: '5–10 days per winter',
        description: 'User Research Panel',
    },
    eleven_to_twenty: {
        id: 'UserResearchPanel.PersonalInvolvement.eleven_to_twenty',
        defaultMessage: '11–20 days per winter',
        description: 'User Research Panel',
    },
    twenty_one_to_fifty: {
        id: 'UserResearchPanel.PersonalInvolvement.twenty_one_to_fifty',
        defaultMessage: '21–50 days per winter',
        description: 'User Research Panel',
    },
    more_than_fifty: {
        id: 'UserResearchPanel.PersonalInvolvement.more_than_fifty',
        defaultMessage: 'More than 50 days per winter',
        description: 'User Research Panel',
    },
    certified_mountain_guide_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_mountain_guide_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as a certified mountain guide?',
        description: 'User Research Panel',
    },
    certified_mountain_guide_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_mountain_guide_year_became_professional',
        defaultMessage: 'What year did you become a certified mountain guide?',
        description: 'User Research Panel',
    },
    certified_ski_guide_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_ski_guide_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as a certified ski guide?',
        description: 'User Research Panel',
    },
    certified_ski_guide_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.certified_ski_guide_year_became_professional',
        defaultMessage: 'What year did you become a certified ski guide?',
        description: 'User Research Panel',
    },
    member_of_an_alpine_search_and_rescue_organization_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_an_alpine_search_and_rescue_organization_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as a member of an alpine search and rescue organization?',
        description: 'User Research Panel',
    },
    member_of_an_alpine_search_and_rescue_organization_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_an_alpine_search_and_rescue_organization_year_became_professional',
        defaultMessage: 'What year did you become a member of an alpine search and rescue organization?',
        description: 'User Research Panel',
    },
    member_of_ski_patrol_managing_avalanche_risk_at_ski_area_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_ski_patrol_managing_avalanche_risk_at_ski_area_days_worked_per_season',
        defaultMessage:
            'How many days per season do you work as a member of ski patrol managing avalanche risk at a ski area?',
        description: 'User Research Panel',
    },
    member_of_ski_patrol_managing_avalanche_risk_at_ski_area_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.member_of_ski_patrol_managing_avalanche_risk_at_ski_area_year_became_professional',
        defaultMessage: 'What year did you become a ski patroller?',
        description: 'User Research Panel',
    },
    avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_days_worked_per_season:
        {
            id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_days_worked_per_season',
            defaultMessage:
                'How many days per season do you work as an avalanche safety technician/engineer managing avalanche risk for infrastructure or worksites?',
            description: 'User Research Panel',
        },
    avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_year_became_professional:
        {
            id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites_year_became_professional',
            defaultMessage:
                'What year did you become an avalanche safety technician/engineer managing avalanche risk for infrastructure or worksites?',
            description: 'User Research Panel',
        },
    avalanche_safety_educator_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_educator_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as an avalanche safety educator?',
        description: 'User Research Panel',
    },
    avalanche_safety_educator_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_safety_educator_year_became_professional',
        defaultMessage: 'What year did you become an avalanche safety educator?',
        description: 'User Research Panel',
    },
    public_avalanche_forecaster_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.public_avalanche_forecaster_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as a public avalanche forecaster?',
        description: 'User Research Panel',
    },
    public_avalanche_forecaster_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.public_avalanche_forecaster_year_became_professional',
        defaultMessage: 'What year did you become a public avalanche forecaster?',
        description: 'User Research Panel',
    },
    avalanche_researcher_days_worked_per_season: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_researcher_days_worked_per_season',
        defaultMessage: 'How many days per season do you work as an avalanche researcher?',
        description: 'User Research Panel',
    },
    avalanche_researcher_year_became_professional: {
        id: 'UserResearchPanel.PersonalInvolvement.avalanche_researcher_year_became_professional',
        defaultMessage: 'What year did you become an avalanche researcher?',
        description: 'User Research Panel',
    },
    knowledge_source_importance: {
        id: 'UserResearchPanel.PersonalInvolvement.knowledge_source_importance',
        defaultMessage:
            'How important have these knowledge sources been in developing your avalanche risk management skills?',
        description: 'User Research Panel',
    },
    knowledge_source_importance_helper_text: {
        id: 'UserResearchPanel.PersonalInvolvement.knowledge_source_importance_helper_text',
        defaultMessage: 'Please select the appropriate option for each source.',
        description: 'User Research Panel',
    },
    knowledge_source_other: {
        id: 'UserResearchPanel.PersonalInvolvement.knowledge_source_other',
        defaultMessage: 'Please specify other knowledge sources',
        description: 'User Research Panel',
    },
    training_courses: {
        id: 'UserResearchPanel.PersonalInvolvement.training_courses',
        defaultMessage: 'What is the highest level of formal avalanche safety training you have completed?',
        description: 'User Research Panel',
    },
    otherAvalancheSafetyTrainingCourse: {
        id: 'UserResearchPanel.PersonalInvolvement.otherAvalancheSafetyTrainingCourse',
        defaultMessage: 'Please specify another level of formal avalanche safety training',
        description: 'User Research Panel',
    },
    yearCompletedHighestLevelTrainingCourse: {
        id: 'UserResearchPanel.PersonalInvolvement.yearCompletedHighestLevelTrainingCourse',
        defaultMessage:
            'What year did you complete your highest level of formal avalanche safety training or had the latest refresher at that level?',
        description: 'User Research Panel',
    },
    yearCompletedHighestLevelTrainingCourseHelperText: {
        id: 'UserResearchPanel.PersonalInvolvement.yearCompletedHighestLevelTrainingCourseHelperText',
        defaultMessage: 'Please enter the year of your highest level course even if it is just approximate.',
        description: 'User Research Panel',
    },
    courseCount: {
        id: 'UserResearchPanel.PersonalInvolvement.courseCount',
        defaultMessage:
            'How many formal avalanche safety courses, including introductory and refresher courses, have you completed?',
        description: 'User Research Panel',
    },
    courseCountHelperText: {
        id: 'UserResearchPanel.PersonalInvolvement.courseCountHelperText',
        defaultMessage: 'Please enter the number of courses you have completed even if it is just approximate.',
        description: 'User Research Panel',
    },
})
