import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, FormControlLabel, Radio, FormHelperText, Select, MenuItem } from '@mui/material'

import { messages, OTHER } from './PersonalInvolvement'
import { isLevelOneOrHigher, trainingCourseMessages } from './TrainingCourseConstants'
import { TRAINING_COURSE_OPTIONS } from './TrainingCourseConstants'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import {
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR,
    TRAINING_COURSE_COUNT,
} from 'components/UserResearchPanel/validationSchema'
import { YearSelect } from 'components/UserResearchPanel/CommonInputs/YearSelect'
import { COURSE_COUNT_OPTIONS } from './TrainingCourseConstants'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

export const TrainingCourses = () => {
    const { data, updateData, setTouched } = UserResearchPanelSignupStore()
    const { formatMessage } = useIntl()

    const handleTrainingCourseChange = (trainingCourse: string) => {
        updateData({ [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED]: trainingCourse })
        setTouched(HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED, true)
    }

    const showOtherField = data[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === OTHER
    const showYearField =
        typeof data[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === 'string' &&
        isLevelOneOrHigher(data[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] as string)

    return (
        <>
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage {...messages.training_courses} />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                {TRAINING_COURSE_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={<Radio />}
                        checked={data[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === option}
                        onChange={() => handleTrainingCourseChange(option)}
                        label={
                            <FormattedMessage
                                {...trainingCourseMessages[option as keyof typeof trainingCourseMessages]}
                            />
                        }
                    />
                ))}
            </FormControl>
            {showOtherField && (
                <TextInput
                    dataKey={HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER}
                    placeholder={formatMessage(messages.otherAvalancheSafetyTrainingCourse)}
                    sx={styles.otherInput}
                    fullWidth
                />
            )}
            {showYearField && (
                <FormControl sx={styles.formControl}>
                    <QuestionText>
                        <FormattedMessage {...messages.yearCompletedHighestLevelTrainingCourse} />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...messages.yearCompletedHighestLevelTrainingCourseHelperText} />
                    </FormHelperText>
                    <YearSelect dataKey={HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR} />
                </FormControl>
            )}
            <FormControl sx={styles.formControl}>
                <QuestionText>
                    <FormattedMessage {...messages.courseCount} />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...messages.courseCountHelperText} />
                </FormHelperText>
                <Select
                    sx={styles.dropdown}
                    value={data[TRAINING_COURSE_COUNT] || ''}
                    onChange={e => {
                        updateData({ [TRAINING_COURSE_COUNT]: e.target.value })
                        setTouched(TRAINING_COURSE_COUNT, true)
                    }}
                    displayEmpty
                >
                    <MenuItem value="">
                        <FormattedMessage
                            description="User Research Panel year dropdown placeholder"
                            defaultMessage="Select number of courses"
                        />
                    </MenuItem>
                    {COURSE_COUNT_OPTIONS.map(count => (
                        <MenuItem key={count} value={count}>
                            <FormattedMessage
                                {...trainingCourseMessages[count as keyof typeof trainingCourseMessages]}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

const styles = {
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    otherInput: {
        marginTop: 2,
    },
    dropdown: {
        width: 300,
        marginTop: 1,
    },
}
