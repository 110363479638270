import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { CONSENT, useUserResearchPanelStep } from 'components/UserResearchPanel/useUserResearchPanelSteps'
import { createUserResearchPanelSchema } from 'components/UserResearchPanel/validationSchema'
import { postUserResearchPanelData } from 'clients/user-research-panel'

export type UserResearchPanelFormData = ReturnType<typeof createUserResearchPanelSchema>['__outputType']
type Touched = {
    [K in keyof UserResearchPanelFormData]?: boolean
}
type Errors = {
    [K in keyof UserResearchPanelFormData]?: string | undefined
}

type State = {
    bannerLastDismissed: number | null
    activeStep: number // See steps in useUserResearchPanelSteps.tsx
    data: UserResearchPanelFormData
    touched: Touched
    errors: Errors
    showSuccessModal: boolean
    submitError: string | null
}

type Action = {
    setBannerLastDismissed: (bannerLastDismissed: State['bannerLastDismissed']) => void
    updateData: (data: object) => void
    updateBooleanValue: (field: string, value: string) => void
    setActiveStep: (activeStep: State['activeStep']) => void
    setTouched: (field: string, isTouched: boolean) => void
    setErrors: (errors: Errors | ((prev: Errors) => Errors)) => void
    postSubmission: (token: string) => void
    setShowSuccessModal: (showSuccessModal: State['showSuccessModal']) => void
    setSubmitError: (error: string | null) => void
}

export const UserResearchPanelSignupStore = create<State & Action>()(
    persist(
        set => {
            return {
                bannerLastDismissed: null,
                activeStep: CONSENT,
                data: {} as UserResearchPanelFormData,
                touched: {},
                errors: {},
                showSuccessModal: false,
                submitError: null,
                setBannerLastDismissed: (bannerLastDismissed: State['bannerLastDismissed']) => {
                    set({ bannerLastDismissed })
                },
                setData: (data: State['data']) => {
                    set({ data })
                },
                updateData: (data: object) => {
                    set(state => ({ data: { ...state.data, ...data } }))
                    set(state => ({ touched: { ...state.touched, ...data } }))
                },
                updateBooleanValue: (field: string, value: string) => {
                    set(state => ({ data: { ...state.data, [field]: value === 'true' } })) // Convert string (as it comes form the radio button) to boolean
                    set(state => ({ touched: { ...state.touched, [field]: true } }))
                },
                setActiveStep: (activeStep: State['activeStep']) => {
                    set({ activeStep })
                },
                setTouched: (field: string, isTouched: boolean) => {
                    set(state => ({
                        touched: { ...state.touched, [field]: isTouched },
                    }))
                },
                setErrors: (errors: Errors | ((prev: Errors) => Errors)) => {
                    set(state => ({ errors: typeof errors === 'function' ? errors(state.errors) : errors }))
                },
                postSubmission: async (token: string) => {
                    try {
                        const data = UserResearchPanelSignupStore.getState().data
                        const response = await postUserResearchPanelData(data, token)

                        if (response.status === 200) {
                            set({ showSuccessModal: true, submitError: null })
                        }
                    } catch (error: unknown) {
                        set({ submitError: (error as AxiosError).response.data.error })
                    }
                },
                setShowSuccessModal: (showSuccessModal: State['showSuccessModal']) => {
                    set({ showSuccessModal })
                },
                setSubmitError: (error: string | null) => {
                    set({ submitError: error })
                },
            }
        },
        {
            name: 'user-research-panel-signup',
            partialize: state => ({
                ...state,
                submitError: null, // Reset submitError on reload
            }),
        }
    )
)

type AxiosError = {
    response: {
        data: {
            error: string
        }
    }
}

// userProgress - Give a number between 0 and 100 based on the number of required steps completed
export const useProgress = () => {
    const intl = useIntl()
    const data = UserResearchPanelSignupStore(state => state.data)

    const schema = createUserResearchPanelSchema(intl)
    const schemaFields = schema.describe().fields

    let totalRequired = 0
    let completedRequired = 0

    Object.entries(schemaFields).forEach(([fieldName, field]) => {
        const schemaField = field as { optional?: boolean }
        if (!schemaField.optional) {
            totalRequired++
            if (data[fieldName as keyof UserResearchPanelFormData]) {
                completedRequired++
            }
        }
    })

    // Calculate percentage (avoid division by zero)
    return totalRequired === 0 ? 0 : Math.round((completedRequired / totalRequired) * 100)
}

export const useActiveStep = () => UserResearchPanelSignupStore(state => state.activeStep)
export const useSetActiveStep = () => UserResearchPanelSignupStore(state => state.setActiveStep)

export const useIsCurrentStepValid = () => {
    const activeStep = useActiveStep()
    const step = useUserResearchPanelStep(activeStep)

    if (!step) return false

    const data = UserResearchPanelSignupStore(state => state.data)

    const { validationSchema } = step
    return validationSchema.isValidSync(data)
}

export const useValidateField = () => {
    const intl = useIntl()
    const setErrors = UserResearchPanelSignupStore(state => state.setErrors)
    const data = UserResearchPanelSignupStore(state => state.data)

    return (field: keyof UserResearchPanelFormData, value: string | string[]) => {
        setErrors((prevErrors: Errors) => {
            const newErrors = { ...prevErrors }
            try {
                createUserResearchPanelSchema(intl).validateSyncAt(field, {
                    ...data,
                    [field]: value,
                })
                delete newErrors[field]
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    newErrors[field] = err.message
                }
            }
            return newErrors
        })
    }
}
